export enum Weapon {
	Empty,
	Hand,
	Pistol,
	Machinegun,
	Shotgun,
	Rifle,
	Halberd,
	Sword,
	Mace,
	Grenade,
	Smoke,
	Medkit,
}
