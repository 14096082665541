export enum LootType {
	Pistol,
	Machinegun,
	Shotgun,
	Rifle,
	Sword,
	Mace,
	Halberd,
	RedAmmo,
	GreenAmmo,
	BlueAmmo,
	OrangeAmmo,
	Vest,
	Medkit,
	Grenade,
	Smoke,
	Scope2,
	Scope4,
	Scope6,
}
